import React from 'react';
import './index.css';
import App from './App';
import Map from './Map';
import Layout from '../components/layout';

export default () => (
  <React.StrictMode>
  <Map />
</React.StrictMode>
);


