import React, { Component } from "react"
import "./App.css"
import MediaQuery from 'react-responsive'
import MainPageHeader from "../components/mainHeader"
import { Link } from "gatsby"
import SEO from "../components/seo"

export default class Home extends Component {
  tester = 'saved1';
  constructor(props) {
    var oneDay = 24 * 60 * 60 * 1000
    var firstDate = new Date()
    var secondDate = new Date("11/3/2020 ")

    var diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay))
    super(props)
    this.state = {
      daysUntilElection: diffDays,
    }
  }

  
  render() {
    return (
      <div>
         <SEO title="Students for 2020" />
        <MediaQuery maxDeviceWidth={1224}>
          <div>
            <div style={{ position: 'absolute',width:"100vw" }}>
              <div class="mobile-headerContainer">
              <a href="#" onClick={(event) => this.setState({ show_overlay: true })}>
                  <div class="mobile-headerOverlayButtonPlaceholder">
                  <img alt="menu-button" src={require('../images/menu_icon.svg')} class="mobile-headerButton"/>
                  </div>
                  </a>
                <Link to="/" style={{ textDecoration: 'none' }}>
                  <text class="mobile-headerText">
                    <text style={{ color: "#FF4747" }}>Students</text>
                    <text style={{ color: "#000000" }}> For </text>
                    <text style={{ color: "#5793FA" }}>2020</text>
                  </text>
                </Link>
                <Link to="/map/" style={{ textDecoration: 'none' }}>
                  <div class="mobile-headerVoteButton">
                    <text class="mobile-headerVoteButtonTexts">Vote</text>
                  </div>
                </Link>
              </div>
              <div class="mobile-main-bodyContainer">
                <text class="mobile-main-titleText">College Students<br />Can Swing The<br />2020 Election.</text>
                <text class="mobile-main-subtitleText">Changing where you vote<br />can increase you impact<br />up to 9x</text>
                <div class="mobile-main-videoPlaceholder">
                  <iframe title="Students for 2020"  src="https://www.youtube.com/embed/QlxRKqTQJOg"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"/>
                </div>
                <Link to="/map/">
                  <div class="mobile-main-button" style={{ position: 'absolute', marginTop: '65.76vh' }}>
                    <text class="mobile-main-buttonText" style={{ marginLeft: '4.27vw' }}>Where Should I Vote?</text>
                  </div>
                </Link>
                <Link to="/volunteer/">
                  <div class="mobile-main-button" style={{ position: 'absolute', marginTop: '73.39vh' }}>
                    <text class="mobile-main-buttonText" style={{ marginLeft: '17.86vw' }}>Volunteer</text>
                  </div>
                </Link>
              </div>
              <div class="mobile-footerContainer">
                <text class="mobile-footerText">
                  <text style={{ color: "#FF4747" }}>Students</text>
                  <text style={{ color: "#ffffff" }}> For </text>
                  <text style={{ color: "#5793FA" }}>2020</text>
                </text>
                <text class="mobile-footerSmallText">2020 © All Rights Reserved</text>
              </div>
            </div>

            <div class="mobile-overlayContainer" style={{ display: this.state.show_overlay === true ? 'block' : 'none' }}>
              <Link to="/" style={{ textDecoration: 'none', marginTop: '5.97vh', position: "absolute" }}><text class="mobile-overlayText">Home</text> </Link>
              <Link to="/about/" style={{ textDecoration: 'none', marginTop: '11.37vh', position: "absolute" }}><text class="mobile-overlayText">Contact Us</text> </Link>
              <Link to="/press/" style={{ textDecoration: 'none', marginTop: '16.77vh', position: "absolute" }}><text class="mobile-overlayText">Press</text> </Link>
              <Link to="/volunteer/" style={{ textDecoration: 'none', marginTop: '22.17vh', position: "absolute" }}><text class="mobile-overlayText">Volunteer</text> </Link>
              <Link to="/map/" style={{ textDecoration: 'none', marginTop: '27.57vh', position: "absolute" }}><text class="mobile-overlayText">Where To Vote</text> </Link>
              <Link to="/map/" style={{ textDecoration: 'none' }}>
                <div class="mobile-overlayButton">
                  <text class="mobile-overlayButtonText">Vote</text>
                </div>
              </Link>
            </div>
            <div class="mobile-overlayDarken" onClick={(event) => this.setState({ show_overlay: false })} style={{ display: this.state.show_overlay === true ? 'block' : 'none' }} />
          </div>
        </MediaQuery>
        <MediaQuery maxDeviceWidth={1224} maxDeviceHeight={600}>
          <div>
            <div style={{ position: 'absolute' }}>
              <div class="mobile-headerContainer">
              <a href="#" onClick={(event) => this.setState({ show_overlay: true })}>
                  <div class="mobile-headerOverlayButtonPlaceholder">
                  <img alt="menu-button" src={require('../images/menu_icon.svg')} class="mobile-headerButton"/>
                  </div>
                  </a>
                <Link to="/" style={{ textDecoration: 'none' }}>
                  <text class="mobile-headerText">
                    <text style={{ color: "#FF4747" }}>Students</text>
                    <text style={{ color: "#000000" }}></text>
                    <text style={{ color: "#5793FA" }}>2020</text>
                  </text>
                </Link>
                <Link to="/map/" style={{ textDecoration: 'none' }}>
                  <div class="mobile-headerVoteButton">
                    <text class="mobile-headerVoteButtonTexts">Vote</text>
                  </div>
                </Link>
              </div>
              <div class="mobile-main-bodyContainer" >
                <text class="mobile-main-titleText" style={{fontSize: "1.4rem"}}>College Students<br />Can Swing The<br />2020 Election.</text>
                <text class="mobile-main-subtitleText" style={{fontSize: "0.9rem" ,marginTop:"18vh"}}>Changing where you vote<br />can increase you impact<br />up to 637px</text>
                <div class="mobile-main-videoPlaceholder">
                  <iframe title="Students for 2020"  src="https://www.youtube.com/embed/QlxRKqTQJOg"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"/>
                </div>
                <Link to="/map/">
                  <div class="mobile-main-button" style={{ position: 'absolute', marginTop: '63.76vh' }}>
                    <text class="mobile-main-buttonText" style={{ marginLeft: '4.27vw' }}>Where Should I Vote?</text>
                  </div>
                </Link>
                <Link to="/volunteer/">
                  <div class="mobile-main-button" style={{ position: 'absolute', marginTop: '73.39vh' }}>
                    <text class="mobile-main-buttonText" style={{ marginLeft: '17.86vw' }}>Volunteer</text>
                  </div>
                </Link>
              </div>
              <div class="mobile-footerContainer">
                <text class="mobile-footerText">
                  <text style={{ color: "#FF4747" }}>Students</text>
                  <text style={{ color: "#ffffff" }}> For </text>
                  <text style={{ color: "#5793FA" }}>2020</text>
                </text>
                <text class="mobile-footerSmallText">info@turnup.us</text>
              </div>
            </div>

            <div class="mobile-overlayContainer" style={{ display: this.state.show_overlay === true ? 'block' : 'none'  }}>
              <Link to="/" style={{ textDecoration: 'none', marginTop: '4.7vh', position: "absolute" }}><text class="mobile-overlayText">Home</text> </Link>
              <Link to="/about/" style={{ textDecoration: 'none', marginTop: '10.37vh', position: "absolute" }}><text class="mobile-overlayText">Contact Us</text> </Link>
              <Link to="/press/" style={{ textDecoration: 'none', marginTop: '15.77vh', position: "absolute" }}><text class="mobile-overlayText">Press</text> </Link>
              <Link to="/volunteer/" style={{ textDecoration: 'none', marginTop: '21.17vh', position: "absolute" }}><text class="mobile-overlayText">Volunteer</text> </Link>
              <Link to="/map/" style={{ textDecoration: 'none', marginTop: '26.57vh', position: "absolute" }}><text class="mobile-overlayText">Where To Vote</text> </Link>
              <Link to="/map/" style={{ textDecoration: 'none' }}>
                <div class="mobile-overlayButton">
                  <text class="mobile-overlayButtonText">Vote</text>
                </div>
              </Link>
            </div>
            <div class="mobile-overlayDarken" onClick={(event) => this.setState({ show_overlay: false })} style={{ display: this.state.show_overlay === true ? 'block' : 'none' }} />
          </div>
        </MediaQuery>
        
        <MediaQuery minDeviceWidth={1224}>
        <div class="bodyDiv">
        <div class="topBar">
          <div class = "logoDiv">
          <Link to="/" style={{ textDecoration: 'none'}}>
            <text class="students-for-2020">
              <text style={{ color: "#FF4747",}}>Students</text>
              <text style={{ color: "#000000" }}> For </text>
              <text style={{ color: "#5793FA" }}>2020</text>
            </text>
          </Link>
          </div>
        <div class = "headerDiv">
          
            <Link to="/press/" style={{ textDecoration: 'none'}}>
              <text class="headerText">Press</text>
            </Link>
          
            <Link to="/volunteer/"  style={{ textDecoration: 'none'}}>
              <text class = "headerText">Volunteer</text>
            </Link>
            <Link to="/about/"  style={{ textDecoration: 'none'}}>
              <text class = "headerText">Contact Us</text>
            </Link>

          </div>
          <div class = "headButtDiv">
          <Link class="headerButton" to="/map/"  style={{ textDecoration: 'none'}}>
            <text class="headerButtonText">Where Should I Vote?</text>
          </Link>
          </div>
        </div>
        <div class="upperSection">
        <MainPageHeader>
          <div class="upperSectionCover">
            <div class="upperSectionData">
            <text class="upperTitle">
              College Students Can
              <br />
              Swing the 2020 Election.
            </text>
            <text class="upperParagraph">
              For the first time in history, students will become the largest
              voting block in a presidential election. Where you vote this year
              matters. We'll show you where your vote will make the most
              difference.
            </text>
            <div class = "upperButtons">
              <Link to="/map/"  style={{ textDecoration: 'none'}}>
                <div class="leftButton">
                  <text class="leftButtonText">Where Should I Vote?</text>
                </div>
              </Link>
              <Link to="/volunteer/"  style={{ textDecoration: 'none'}}>
                <div class="rightButton">
                  <text class="rightButtonText">Volunteer Your Time!</text>
                </div>
              </Link>
              </div>
              </div>
          </div>
        </MainPageHeader>
        </div>
        <div class="middleBar">
          <div class="middleBarText">
          <text class="studentCount">6M Students</text>
          <text class="studentCount">17 'Student's Choice' States</text>
          <text class="studentCount">{this.state.daysUntilElection} Days Until Election </text>
        </div>
        </div>

        <div class="lowerSection">
          <br/>
          <br/>
          <text class="lowerTitle">
            Learn how changing your voting location
            <br />
            can determine who wins this year.{" "}
          </text>
          <div class="videoEmbedContainer">
                <iframe title="Students for 2020" width='637px' height='473px' src="https://www.youtube.com/embed/QlxRKqTQJOg"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
          </div>
        </div>

        <div class="bottomBar">
          <Link to="/"  style={{ textDecoration: 'none'}}>
            <text class="students-for-2020">
              <text style={{ color: "#FF4747" }}>Students</text>
              <text style={{ color: "#ffffff" }}> For </text>
              <text style={{ color: "#5793FA" }}>2020</text>
            </text>
          </Link>
          <div class = "rightsReservedDiv">
          <text class="rightsReserved">info@turnup.us</text>
          </div>
        </div>
      </div>
        </MediaQuery>
      </div>
    );
  }
}

